import React, {useEffect, useState} from "react";
import {Button, Layout, Space, Spin} from "antd";
import {Ingredient} from "src/types/ingredient";
import {Function} from "src/types/function";
import IngredientsCard from "src/components/IngredientsCard";
import {getCachedIngredients} from "src/api/cache/ingredientsCache";
import MastersCard from "src/components/MastersCard";
import {User} from "src/types/user";
import {useNavigate, useParams} from "react-router-dom";
import {getFunctions} from "src/api/functionApi";
import {
  ADD_INGREDIENT_PAGE,
  EDIT_INGREDIENT_PAGE,
  FUNCTION_DESCRIPTION_PAGE,
  HOME_PAGE,
  INGREDIENT
} from "src/constants/appConstants";
import "../css/style.css";
import {
    editIngredientsContentStyle,
    editIngredientsHeaderStyle,
    editIngredientsLayoutStyle,
    editIngredientsPageStyle, editIngredientsSiderStyle
} from "src/constants/styleConstants";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";
import Delete from "src/components/common/Delete";

interface EditIngredientsProps {
    user: User,
    edit: boolean
}

const { Header, Content, Sider } = Layout;

const EditIngredients: React.FC<EditIngredientsProps> = ({user, edit}) => {
    const navigate = useNavigate();
    const initialTime = Date.now();
    const params = useParams();
    useNavigate();
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [classifications, setClassifications] = useState<string[]>([]);
    const [masters, setMasters] = useState<string[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [statuses, setStatuses] = useState<string[]>([]);
    const [functions, setFunctions] = useState<Function[]>([]);
    const [ingredientIsLoading, setIngredientLoading] = useState<boolean>(true);
    const [isDeleteIngredientModalVisible, setDeleteIngredientModalVisible] = useState(false);

    const handleDelete = () => {
        setDeleteIngredientModalVisible(false);
        navigate(HOME_PAGE);
        navigate(0);
    }

    const getFunctionsHandler = () => {
        getFunctions()
            .then(functions => {
                if(functions) {
                    setFunctions(functions)
                }
            })
    }

    // Fetch ingredients from API and Cache
    useEffect(() => {
        getFunctionsHandler();
        getCachedIngredients()
            .then(cachedIngredients => {
                setIngredients(cachedIngredients);
                setIngredientLoading(false);
                const classifications = new Set<string>();
                const types = new Set<string>();
                const statuses = new Set<string>();
                cachedIngredients.forEach(({classification, acceptability}) => {
                    if (classification && classification.trim().length > 0) {
                        classifications.add(classification);
                    }
                    acceptability.forEach(acceptable => {
                        if(acceptable.type && acceptable.type.trim().length > 0) {
                            types.add(acceptable.type);
                        }
                        if(acceptable.status && acceptable.status.trim().length > 0) {
                            statuses.add(acceptable.status);
                        }
                    })
                })
                setClassifications(Array.from(classifications));
                setMasters(Array.from(classifications));
                setTypes(Array.from(types));
                setStatuses(Array.from(statuses))
            });
      const metricsPublisher = getMetricsPublisher();
      if (edit && params["id"]) {
        metricsPublisher.publishTimerMetric(EDIT_INGREDIENT_PAGE.replace(':id', params["id"]),
          MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
        metricsPublisher.publishCounterMetric(EDIT_INGREDIENT_PAGE.replace(':id', params["id"]),
          MetricKey.CLICKS, 1);
      } else {
        metricsPublisher.publishTimerMetric(ADD_INGREDIENT_PAGE,
          MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
        metricsPublisher.publishCounterMetric(ADD_INGREDIENT_PAGE, MetricKey.CLICKS, 1);
      }
    }, [])

    return (
        <div>
            {
                ingredientIsLoading ? (
                    <div className={"ingredient-loading-screen"}>
                        <Spin size="large" />
                    </div>
                ) : (
                <Space style={editIngredientsPageStyle} direction="vertical">
                    <Layout>
                        <Header style={editIngredientsHeaderStyle}>
                            <h1>{edit ? "Edit Ingredient" : "Add New Ingredient"}</h1>
                            {
                                edit && (
                                    <Button type="primary" danger onClick={() => {
                                        setDeleteIngredientModalVisible(true)
                                    }}>
                                        Delete
                                    </Button>
                                )
                            }
                        </Header>
                        <Layout style={editIngredientsLayoutStyle}>
                            <Content style={editIngredientsContentStyle}>
                                {
                                    edit ? (
                                        <IngredientsCard id={params["id"]} ingredients={ingredients} classifications={classifications.sort()} masters={masters.sort()} types={types.sort()} statuses={statuses.sort()} functions={functions} getFunctionsHandler={getFunctionsHandler} user={user}/>
                                    ) : (
                                        <IngredientsCard ingredients={ingredients} classifications={classifications.sort()} masters={masters.sort()} types={types.sort()} statuses={statuses.sort()} functions={functions} getFunctionsHandler={getFunctionsHandler} user={user}/>
                                    )
                                }
                            </Content>
                            <Sider width={300} style={editIngredientsSiderStyle}>
                                <MastersCard data={types.sort()} cardType={"Types"} updateData={setTypes}/>
                                <MastersCard data={classifications.sort()} cardType={"Classifications"} updateData={setClassifications}/>
                            </Sider>
                        </Layout>
                    </Layout>
                    {
                        params?.["id"] && (
                            <Delete
                                open={isDeleteIngredientModalVisible}
                                onConfirm={handleDelete}
                                onCancel={() => setDeleteIngredientModalVisible(false)}
                                type={INGREDIENT}
                                id={params["id"]}
                            />
                        )
                    }
                </Space>
                )
            }
        </div>
    )
}

export default EditIngredients;